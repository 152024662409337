import request from '@/utils/request'

export function WxSaveCheck (data) {
  return request({
    url: 'CheckRisk/WxSaveCheck',
    method: 'post',
    data: data
  })
}

export function WeGetEnterpriseInfo (query) {
  return request({
    url: 'CheckRisk/WeGetEnterpriseInfo',
    method: 'get',
    params: query
  })
}
export function VWxSaveCheck (data) {
  return request({
    url: 'Vaccine/WxSaveCheck',
    method: 'post',
    data: data
  })
}